import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appBlockSpecialChar]'
})
export class BlockSpecialCharDirective {
  @Input('appBlockSpecialChar') blockSpecialChar: boolean;
  @Input('searchTermPattern') searchPattenRegex: string;
  @Input('pasteSearchPattern') pasteSearchPattern: string;

  constructor() {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event) {
    if (this.blockSpecialChar) {
      return new RegExp(this.searchPattenRegex).test(event.key);
    }
    return null;
  }

  /* istanbul ignore next */
  @HostListener('paste', ['$event'])
  onPaste(event) {
    if (this.blockSpecialChar) {
      const Value = pastedInput => {
        if (this.pasteSearchPattern) {
          pastedInput = pastedInput.replace(new RegExp(this.pasteSearchPattern, 'g'), '');
        }
        return pastedInput;
      };
      if (event.clipboardData) {
        navigator.clipboard.writeText(Value(event.clipboardData.getData('text/plain')));
      } else {
        //for I.E
        window['clipboardData'].setData('Text', Value(window['clipboardData'].getData('Text')));
      }
    }
  }
}
