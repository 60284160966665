import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlockSpecialCharDirective } from './block-special-char.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { CcInputFieldModule } from '@cat-digital-workspace/shared-ui-core/input-field';

export { SearchComponent };
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoModule, CcInputFieldModule],
  declarations: [SearchComponent, BlockSpecialCharDirective],
  exports: [SearchComponent]
})
export class SearchModule {}
