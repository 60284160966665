import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SEARCH_ICON_URL, searchConstans } from '../search.config';
import { TRANSLATIONS } from './../search.config';
export interface Style {
  [key: string]: string;
}

@Component({
  selector: 'app-widget-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() hidesearchBox: boolean;
  @Input() suggestions: any;
  @Input() displayProperty: string;
  @Input() minLengthSearch: number;
  @Input() maxLengthSearch: number;
  @Input() placeHolderText: string;
  @Input() emptyRecordsText: string;
  @Input() ghostText: string;
  @Input() makeSearchTextEmpty: string;
  @Input() isFocused: boolean;
  @ViewChild('searchTextBox') searchElement: ElementRef;
  @Input() searchValue = '';
  @Input() inputParentContainer?: Style;
  @Input() customInputStyles?: Style;
  @Input() cancelButtonClass?: string;
  @Input() blockSpecialChar = false;
  @Input() searchTermPattern = '*';
  @Input() pasteSearchPattern?: string;
  @Input() typeInSearch = false;
  @Input() trimSpaceOnsearch = false;
  @Input() autocomplete? = 'off';
  @Input() debounceTime: number = searchConstans.DEBOUNCE_TIME;
  @Input() searchIcon = SEARCH_ICON_URL;
  @Input() searchType = 'pageControl';
  @Input() isFloating = false;
  @Input() mobileHeader = '';
  @Input() enableMobileOverlay = 'true';
  @Input() helpText = '';

  @Output() searchValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() keyPress: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emptySearchText: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() keyEnter: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedSuggestion: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeSearchBox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isInputFocused?: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() hideSuggestions?: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() searchClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickedOutside: EventEmitter<boolean> = new EventEmitter<boolean>();

  previousSearchText = '';
  isHideSuggestions = true;
  searchControl = new FormControl();
  searchCtrlSubscriber: Subscription;

  isEmptyResults = false;
  isSuggestionSelected = false;
  isFocus = false;
  hideGhostMsgAfterMinChar = true;
  searchTriggeredByEnter = false;

  spaceTrimmedSearchText = '';

  isInit = true;
  TRANSLATIONS = TRANSLATIONS;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.suggestions = [];
    this.mobileHeader = this.mobileHeader || this.placeHolderText;
    this.searchCtrlSubscriber = this.searchControl.valueChanges
      .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
      .subscribe(values => {
        let value = values;
        if (!values) {
          value = '';
        }
        if (!this.isInit && values && !this.searchTriggeredByEnter) {
          this.isFocus = true;
        }
        this.searchValueChange.emit(value);
        const isEmptyVal = this.trimSpaceOnsearch ? value.length === 0 : value.trim().length === 0;
        if (!this.isInit) {
          this.emptySearchText.emit(isEmptyVal);
        }

        this.isInit = false;
        if (isEmptyVal && !this.isFocus) {
          this.hideGhostText();
        }
        let inputValue = values;
        if (this.trimSpaceOnsearch && values) {
          inputValue = inputValue.replace(/\s+/g, ' ').trim();
          this.searchControl.setValue(inputValue, { emitEvent: false });
          this.hideGhostMsgAfterMinChar = inputValue.length <= 0 ? true : false;
        }
        this.emitValueOnSuggestions(inputValue);
      });
  }

  ngAfterViewInit() {
    if (this.isFocused) {
      this.searchElement.nativeElement.focus();
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    const canEmptyTextInSearch = input => input && input.currentValue !== input.previousValue;
    const doEmptyText = canEmptyTextInSearch(changes['makeSearchTextEmpty']);
    if (doEmptyText) {
      this.previousSearchText = '';
      this.searchControl.setValue('');
      this.displayGhostText();
    }
    if (changes['suggestions']) {
      if (this.searchControl.value && this.searchControl.value.length >= this.minLengthSearch) {
        if (this.suggestions.length > 0) {
          this.isEmptyResults = false;
          this.emitHideSuggestions();
        } else {
          if (!this.searchTriggeredByEnter) {
            this.isHideSuggestions = false;
          }
          this.isEmptyResults = true;
          this.hideSuggestions.emit(true);
        }
      }
      this.updateHideSuggestionsOnEnter();
    }
  }

  emitHideSuggestions(): void {
    if (this.isFocus) {
      this.isHideSuggestions = false;
      this.hideSuggestions.emit(false);
    } else {
      this.isHideSuggestions = true;
      this.hideSuggestions.emit(true);
    }
  }

  updateHideSuggestionsOnEnter(): void {
    if (this.searchTriggeredByEnter) {
      this.searchTriggeredByEnter = false;
    }
  }

  emitValueOnSuggestions(inputValue: any): void {
    if (!this.isSuggestionSelected) {
      this.suggestions = [];
      if (inputValue && inputValue.length >= this.minLengthSearch) {
        this.hideGhostMsgAfterMinChar = false;
        this.isEmptyResults = false;
        this.keyPress.emit(inputValue);
      }
    } else {
      this.isSuggestionSelected = false;
    }
  }

  onEnter(value) {
    if ((this.previousSearchText === '' || this.previousSearchText !== value) && value.length >= this.minLengthSearch) {
      this.previousSearchText = value;
      this.isFocus = false;
      this.searchTriggeredByEnter = true;
      this.keyEnter.emit(value);
      this.isHideSuggestions = true;
      this.hideSuggestions.emit(true);
    }
  }

  focusInput(focus: boolean) {
    if (focus) {
      if (!this.searchValue || this.searchValue.length === 0) {
        this.displayGhostText();
      }
      this.isHideSuggestions = false;
    } else if (!this.isFocus) {
      this.hideGhostText();
    }
    this.isFocus = focus;
    this.isInputFocused.emit(focus);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      event?.target !== this.searchElement.nativeElement &&
      event?.target?.className?.baseVal !== '' &&
      typeof event?.target?.className === 'string' &&
      !event?.target?.className?.match('search-icon')
    ) {
      if (!this.isInit) {
        this.isFocus = false;
      }
      this.isHideSuggestions = true;
      this.hideSuggestions.emit(true);
      this.suggestions = [];
      this.clickedOutside.emit(true);
    }
  }
  onSelectSuggestion(suggestion) {
    this.isSuggestionSelected = true;
    this.searchValue = suggestion[this.displayProperty];
    this.selectedSuggestion.emit(suggestion);
  }

  closeSearchInputBox() {
    this.emptySearchText.emit(this.previousSearchText !== this.searchValue);
    this.closeSearchBox.emit(true);
  }

  onClickFocusInput() {
    this.searchElement.nativeElement.focus();
    this.focusInput(true);
  }
  onClearText() {
    this.isHideSuggestions = true;
    this.hideSuggestions.emit(true);
    this.suggestions = [];
    this.previousSearchText = '';
    this.displayGhostText();
    if (!this.isInit) {
      this.isFocus = true;
    }
  }

  hideGhostText() {
    this.hideGhostMsgAfterMinChar = false;
  }

  displayGhostText() {
    this.hideGhostMsgAfterMinChar = true;
    this.isEmptyResults = false;
  }

  onChange(event) {
    this.spaceTrimmedSearchText = this.trimSpaceOnsearch && event ? event.replace(/\s+/g, ' ').trim() : event;
    this.searchValue = event;

    if ((!this.searchValue || (this.searchValue && this.searchValue.length === 0)) && this.isFocus) {
      this.onClearText();
    } else {
      this.hideGhostText();
    }
  }

  clearSearchText(event?) {
    const value = event ? event.value : '';
    this.searchControl.setValue(value);
  }

  emitClick() {
    this.searchClick.emit(true);
  }
  ngOnDestroy() {
    if (this.searchCtrlSubscriber) {
      this.searchCtrlSubscriber.unsubscribe();
    }
  }
}
